body::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(249, 247, 252, 0.317);
    z-index: -1;
}

body {
    background-image: url('./bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
}

.container {
    background: rgba(255, 255, 255, 0.9);
    padding: 30px 40px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    overflow: hidden;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.top-left-image {
    max-width: 100%;
    height: auto;
 }
 

h2 {
    text-align: center;
    margin-bottom: 25px;
    color: #444;
}

label {
    display: inline-block; /* Display label inline */
    margin-right: 10px;    /* Add some spacing between the label and the input field */
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 10px;
}

input[type="text"], textarea, select, input[type="date"] {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 16px;
    display: inline-block; /* Display input fields inline */
}

textarea {
    resize: vertical;
}

.input-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

input[type="submit"] {
    cursor: pointer;
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;
}

input[type="submit"]:hover {
    background-color: #0056b3;
}
